import { registerLicense } from "@syncfusion/ej2-base";
import { Kanban } from "@syncfusion/ej2-kanban";
import { DropDownList } from "@syncfusion/ej2-dropdowns";

var kanbanObj = null;
var dropDownListEmpresa = null;
var dropDownListFornecedor = null;
var dropDownListItem = null;

class SyncFusionKanban {
  constructor({ wrapper, element, dataSource }) {
    try {
      if (!wrapper) {
        throw new Error("Wrapper is required to initialize SyncFusionKanban.");
      }
      this.$wrapper = $(wrapper);
      this.element = element || "#Kanban";
      // this.showBetaWarning();
      registerLicense("Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdmWX5dcXRTR2VdUUV+XUs=")
      applyTheme();

      // Armazenar dataSource original e estado atual
      this.originalDataSource = dataSource;
      this.currentEmpresa = localStorage.getItem("selectedEmpresa") || "Mostrar todas as empresas";
      this.currentFornecedor = localStorage.getItem("selectedFornecedor") || "Mostrar todos os fornecedores";
      this.currentItem = localStorage.getItem("selectedItem") || "Mostrar todos os itens";

      const filterContainer = document.createElement("div");
      filterContainer.id = "filterContainer";
      filterContainer.style.display = "flex";
      filterContainer.style.justifyContent = "flex-start";
      filterContainer.style.gap = "20px";
      filterContainer.style.marginBottom = "10px";
      filterContainer.style.marginTop = "20px";

      const dropdownEmpresa = document.createElement("div");
      dropdownEmpresa.id = "dropdownEmpresa";
      dropdownEmpresa.style.width = "250px";
      filterContainer.appendChild(dropdownEmpresa);

      const dropdownFornecedor = document.createElement("div");
      dropdownFornecedor.id = "dropdownFornecedor";
      dropdownFornecedor.style.width = "250px";
      filterContainer.appendChild(dropdownFornecedor);

      const dropdownItem = document.createElement("div");
      dropdownItem.id = "dropdownItem";
      dropdownItem.style.width = "250px"
      filterContainer.appendChild(dropdownItem);

      // Inserir o container de filtros antes do elemento Kanban
      this.$wrapper.prepend(filterContainer);

      dropDownListEmpresa = new DropDownList({
        dataSource: ["Mostrar todas as empresas", ...this.getUniqueCompanies(dataSource)],
        value: this.currentEmpresa,
        placeholder: "Selecione uma empresa",
        change: (args) => {
          this.currentEmpresa = args.value;
          localStorage.setItem("selectedEmpresa", args.value);
          this.updateKanbanData();
          this.updateDropDownLists();
        },
      });
      dropDownListEmpresa.appendTo("#dropdownEmpresa");

      dropDownListFornecedor = new DropDownList({
        dataSource: ["Mostrar todos os fornecedores", ...this.getUniqueFornecedores(dataSource)],
        value: this.currentFornecedor,
        placeholder: "Selecione um fornecedor",
        change: (args) => {
          this.currentFornecedor = args.value;
          localStorage.setItem("selectedFornecedor", args.value);
          this.updateKanbanData();
        },
      });
      dropDownListFornecedor.appendTo("#dropdownFornecedor");

      dropDownListItem = new DropDownList({
        dataSource: ["Mostrar todos os itens", ...this.getUniqueItems(dataSource)],
        value: this.currentItem,
        placeholder: "Selecione um item",
        change: (args) => {
          this.currentItem = args.value;
          localStorage.setItem("selectedItem", args.value);
          this.updateKanbanData();
        },
      });
      dropDownListItem.appendTo("#dropdownItem");

      // Inicializar Kanban com dados filtrados
      const initialData = this.getFilteredData();

      kanbanObj = new Kanban({
        dataSource: initialData,
        keyField: "doctype",
        allowDragAndDrop: false,
        columns: [
          {
            headerText: "ESTOQUE MíNIMO",
            keyField: "estoque item",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-box icon-estoque"></i>' +
              '<span>Estoque Mínimo</span>' +
              '</div>' +
              '<button class="kanban-button">Gerar Solicitação</button>' +
              '</div>',
          },
          {
            headerText: "SOLICITAÇÃO",
            keyField: "Solicitacao",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-paper-plane icon-solicitacao"></i>' +
              '<span>Solicitação</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/solicitacao/new-solicitacao-jmyrfanpir#solciita%C3%A7%C3%A3o_tab" target="_blank">` +
              '<button class="kanban-button">Nova Solicitação</button>' +
              '</a>' +
              '</div>',
          },
          {
            headerText: "COTAÇÃO",
            keyField: "Cotacao",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-chart-bar icon-cotacao"></i>' +
              '<span>Cotação</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/cotacao/new-cotacao-amonfprpar#principal_tab" target="_blank">` +
              '<button class="kanban-button">Nova Cotação</button>' +
              '</a>' +
              '</div>',
          },
          {
            headerText: "ORDEM DE COMPRA",
            keyField: "Ordem de Compra",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-cart-arrow-down icon-ordem-compra"></i>' +
              '<span>Ordem de Compra</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/ordem-de-compra/new-ordem-de-compra-ppcatlbgmz#principal_tab" target="_blank">` +
              '<button class="kanban-button">Nova Ordem de Compra</button>' +
              '</a>' +
              '</div>',
          },
          {
            headerText: "ST SEM RETORNO 100%",
            keyField: "Envio para industrializacao",
            template:
              '<div class="kanban-column">' +
              '<div class="kanban-header">' +
              '<i class="fas fa-industry icon-industrializacao"></i>' +
              '<span>ST Sem Retorno</span>' +
              '</div>' +
              `<a href="${window.location.origin}/app/envio-para-industrializacao?status=Aberto" target="_blank">` +
              '<button class="kanban-button">Novo ST Sem Retorno</button>' +
              '</a>' +
              '</div>',
          },
        ],

        cardSettings: {
          contentField: "observacao",
          headerField: "name",
          allowEditing: false,
          template: function (data) {
            if (data.doctype == "Solicitacao") {
              // const emissionColor = getExpirationColor(data.data_solicitacao);
              const necessityColor = getExpirationColor(data.data_da_necessidade);
              return `
                <div class="e-card-grid trello-card">
                  <div class="e-card-header">
                      <strong>
                          <a href="${window.location.origin}/app/solicitacao/${data.name}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"}
                          </a>
                      </strong>
                  </div>
                  <div class="e-card-content">
                    <div class="e-card-item bordered-item">
                      <strong><i class="fas fa-cogs icon-item"></i> Item:</strong> ${data.codigo_item ? `${data.codigo_item} - ${data.item_descricao || ""} (${data.quantidade} ${data.unidade || ""}) ` : "N/A"
                }
                    </div>
                      <div class="e-card-solicitante bordered-solicitante">
                      <strong>Solicitante:</strong> ${data.solicitante || "N/A"
                }
                    </div>
                     <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${formatarData(data.data_solicitacao) || "N/A"
                }
                    </div>
                    <div class="e-card-data-necessidade bordered-data-necessidade" style="background-color: ${necessityColor};">
                      <strong>Data Necessidade:</strong> ${formatarData(data.data_da_necessidade) || "N/A"
                }
                    </div>
                  </div>
                </div> 
                `;
            }
            else if (data.doctype == "Ordem de Compra") {
              const deliveryColor = getExpirationColor(data.data_prevista_de_entrega);
              return `
                  <div class="e-card-grid trello-card">
                    <div class="e-card-header">
                        <strong>
                           <a href="${window.location.origin}/app/ordem-de-compra/${data.name}#principal_tab" target="_blank">
                              <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"} 
                          </a>
                      </strong>
                  </div>
                <div>
                  <div class="e-card-content">
                    <div class="e-card-fornecedor bordered-fornecedor">
                      <strong>Fornecedor:</strong> ${data.descricao_fornecedor || "N/A"
                }
                    </div>
                    <div class="e-card-itens bordered-itens clickable" onclick="openModal('${data.name}', 'Ordem de Compra')">
                      <i class="fas fa-cogs icon-itens"></i> Itens (${data.itens.length}) <i class="fas fa-chevron-down"></i></strong>
                    </div>
                    <div class="e-card-data-emissao bordered-data-emissao">
                      <strong>Data de Emissão:</strong> ${formatarData(data.data_de_emissão) || "N/A"
                }
                    </div>
                    <div class="e-card-data-prevista bordered-data-prevista" style="background-color: ${deliveryColor};">
                      <strong>Data Prevista Entrega:</strong> ${formatarData(data.data_prevista_de_entrega) || "N/A"
                }
                    </div>
                    <div class="e-card-valor-total bordered-valor-total">
                      <strong>Valor Total:</strong> ${'R$ ' + data.total
                }
                    </div>
                    <div class="e-card-estagio bordered-estagio">
                      <strong>Estágio:</strong> ${data.estágio_ordem_de_compra || "N/A"
                }
                  </div>
                  </div>
                </div>
                `;
            }
            else if (data.doctype == "Cotacao") {
              const expirationColor = getExpirationColor(data.data_expiracao);
              return `
                <div class="e-card-grid trello-card">
                  <div class="e-card-header">
                    <strong>
                      <a href="${window.location.origin}/app/cotacao/${data.name}#principal_tab" target="_blank">
                          <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"}
                      </a>
                    </strong>
                  </div>
                  <div>
                    <div class="e-card-content">
                      <div class="e-card-fornecedor bordered-fornecedor">
                        <strong>Fornecedor 1:</strong> ${data.fornecedores_cotacao[0]?.descricao_fornecedor || "N/A"}
                      </div>
                      ${data.fornecedores_cotacao.length > 1 ? `
                      <div class="e-card-fornecedor bordered-fornecedor">
                        <strong>Fornecedor 2:</strong> ${data.fornecedores_cotacao[1]?.descricao_fornecedor || "N/A"}
                      </div>` : ''}
                      ${data.fornecedores_cotacao.length > 2 ? `
                      <div class="e-card-fornecedor bordered-fornecedor">
                        <strong>Fornecedor 3:</strong> ${data.fornecedores_cotacao[2]?.descricao_fornecedor || "N/A"}
                      </div>` : ''}
                      <div class="e-card-itens bordered-itens clickable" onclick="openModal('${data.name}', 'Cotacao')">
                        <i class="fas fa-cogs icon-itens"></i> Itens (${data.itens.length}) <i class="fas fa-chevron-down"></i></strong>
                      </div>
                      <div class="e-card-data-emissao bordered-data-emissao">
                        <strong>Data de Emissão:</strong> ${formatarData(data.creation || "N/A")
                }
                      </div>
                      <div class="e-card-data-expiracao bordered-data-expiracao" style="background-color: ${expirationColor};">
                        <strong>Data de Expiração:</strong> ${formatarData(data.data_expiracao || "N/A")
                }
                      </div>
                      <div class="e-card-estagio bordered-estagio">
                        <strong>Estágio:</strong> ${data.estagio_da_cotacao || "N/A"
                }
                      </div>
                    </div>
                  </div>
                </div>
              `;
            }
            else if (data.doctype == "estoque item") {
              return `
                <div class="e-card-grid trello-card">
                  <input type="checkbox" class="card-checkbox" onchange="handleCheckboxChange(this, '${data.name}')" />
                  <div class="e-card-header">
                    <strong>
                        <a href="${window.location.origin}/app/item/${data.parent}#principal_tab" target="_blank">
                          <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.codigo || "N/A"} - ${data.descricao || "N/A"}
                        </a>
                    </strong>
                  </div>
                  <div class="e-card-content">
                   <div class="e-card-item bordered-item">
                      <strong>Estoque Atual:</strong> ${data.em_estoque || 0}
                    </div>
                    <div class="e-card-item bordered-item">
                      <strong>Estoque Mínimo:</strong> ${data.estoque_min || 0}
                    </div>
                    <div class="e-card-item bordered-item">
                      <strong>Lote Econômico:</strong> ${data.lote_eco || 0}
                    </div>
                    <div class="quantidade-container">
                      <label for="quantidade-solicitar-${data.name}">Quantidade a Solicitar:</label>
                      <input type="number" id="quantidade-solicitar-${data.name}" value="${data.lote_eco || 1}" />
                    </div>
                  </div>
                </div>
              `;
            }
            else if (data.doctype == "Envio para industrializacao") {
              const deliveryColor = getExpirationColor(data.dt_previsao_retorno);
              return `
                <div class="e-card-grid trello-card">
                  <div class="e-card-header">
                    <strong>
                      <a href="${window.location.origin}/app/envio-para-industrializacao/${data.name}#principal_tab" target="_blank">
                        <span class="link-icon">→</span> <span class="link-text">Ir para</span> ${data.name || "N/A"}
                      </a>
                    </strong>
                  </div>
                  <div class="e-card-content">
                    <div class="e-card-fornecedor bordered-fornecedor">
                      <strong>Fornecedor:</strong> ${data.descricao_fornecedor || "N/A"}
                    </div>
                    <div class="e-card-itens bordered-itens clickable" onclick="openModal('${data.name}', 'Envio para industrializacao')">
                      <i class="fas fa-cogs icon-itens"></i> Itens (${data.itens.length}) <i class="fas fa-chevron-down"></i></strong>
                    </div>
                    <div class="e-card-data-saida bordered-data-saida">
                      <strong>Data da Saída:</strong> ${formatarData(data.dt_saida) || "N/A"}
                    </div>
                    <div class="e-card-data-prevista-retorno bordered-data-prevista-retorno" style="background-color: ${deliveryColor};">
                      <strong>Data Prevista de Retorno:</strong> ${formatarData(data.dt_previsao_retorno) || "N/A"}
                    </div>
                  </div>
                </div>
              `;
            }
          },
        },
        dialogOpen: 'dialogOpen',
        width: '100%',
        height: 'auto',
        minWidth: '300px',
        minHeight: '400px',
      });

      // Anexar instância ao kanbanObj para acesso global
      kanbanObj.syncFusionInstance = this;
      real_time();
      kanbanObj.appendTo(this.element);

      // Adicionar evento de clique ao botão "Gerar Solicitação"
      document.addEventListener('click', async (event) => {
        if (event.target && event.target.classList.contains('kanban-button')) {
          const columnHeader = event.target.closest('.kanban-column').querySelector('.kanban-header span').innerText;
          if (columnHeader === 'Estoque Mínimo') {
            await kanbanObj.syncFusionInstance.gerarSolicitacao();
          }
        }
      });
    } catch (e) {
      console.error("Error initializing SyncFusionKanban:", e);
    }
  }

  getUniqueCompanies(data) {
    return [...new Set(data.filter(e => e.nome_empresa).map(e => e.nome_empresa))];
  }

  getUniqueFornecedores(data) {
    const fornecedores = new Set();
    data.forEach(data => {
      if (data.descricao_fornecedor) {
        fornecedores.add(data.descricao_fornecedor);
      }
      if (data.fornecedor_cotacao) {
        data.fornecedor_cotacao.forEach(fornecedor => {
          if (fornecedor.descricao_fornecedor) {
            fornecedores.add(fornecedor.descricao_fornecedor);
          }
        });
      }
    });
    return [...fornecedores];
  }

  getUniqueItems(data) {
    const itens = new Set();
    data.forEach(data => {
      if (data.codigo_item) {
        itens.add(`${data.codigo_item} - ${data.item_descricao || ""}`);
      } else if (data.item_descricao) {
        itens.add(data.item_descricao);
      }
      if (data.itens) {
        data.itens.forEach(data => {
          if (data.codigo_item) {
            itens.add(`${data.codigo_item} - ${data.descricao_item || ""}`);
          } else if (data.item_desc) {
            itens.add(data.item_desc);
          }
        });
      }
    });
    return [...itens];
  }

  getFilteredData() {
    if (!this.originalDataSource) return [];
    return this.originalDataSource.filter(item => {
      const empresaMatch = this.currentEmpresa === "Mostrar todas as empresas" || item.nome_empresa === this.currentEmpresa;
      const fornecedorMatch = this.currentFornecedor === "Mostrar todos os fornecedores" || item.descricao_fornecedor === this.currentFornecedor || (item.fornecedor_cotacao && item.fornecedor_cotacao.some(fornecedor => fornecedor.descricao_fornecedor === this.currentFornecedor));
      
      const itemMatch = this.currentItem === "Mostrar todos os itens" || 
        // Match por código e descrição
        (item.codigo_item && `${item.codigo_item} - ${item.item_descricao || ""}` === this.currentItem) ||
        // Match apenas por descrição
        (!item.codigo_item && item.item_descricao === this.currentItem) ||
        // Match nos itens filhos
        (item.itens && item.itens.some(subItem => 
          (subItem.codigo_item && `${subItem.codigo_item} - ${subItem.descricao_item || ""}` === this.currentItem) ||
          (!subItem.codigo_item && subItem.item_desc === this.currentItem)
        ));
      
      return empresaMatch && fornecedorMatch && itemMatch;
    });
  }

  updateKanbanData() {
    if (!kanbanObj) return;
    const filteredData = this.getFilteredData();
    kanbanObj.dataSource = filteredData;
  }

  // Método para atualizar dados e manter filtro
  updateDataSource(newData) {
    this.originalDataSource = newData;
    this.updateKanbanData();
    this.updateDropDownLists();
  }

  updateDropDownLists() {
    if (!dropDownListEmpresa || !dropDownListFornecedor || !dropDownListItem) return;

    const empresas = ["Mostrar todas as empresas", ...this.getUniqueCompanies(this.originalDataSource)];
    const fornecedores = ["Mostrar todos os fornecedores", ...this.getUniqueFornecedores(this.originalDataSource)];
    const itens = ["Mostrar todos os itens", ...this.getUniqueItems(this.originalDataSource)];

    dropDownListEmpresa.dataSource = empresas;
    dropDownListEmpresa.value = this.currentEmpresa;
    dropDownListEmpresa

    dropDownListFornecedor.dataSource = fornecedores;
    dropDownListFornecedor.value = this.currentFornecedor;
    dropDownListFornecedor

    dropDownListItem.dataSource = itens;
    dropDownListItem.value = this.currentItem;
    dropDownListItem
  }

  refreshKanban() {
    kanbanObj.refresh();
  }

  async gerarSolicitacao() {
    if (!window.selectedItems || window.selectedItems.size === 0) {
      this.showCustomModal('Atenção', 'Selecione ao menos um item para gerar a solicitação.');
      return;
    }

    const selectedItemsArray = Array.from(window.selectedItems).map(name => ({ ...kanbanObj.dataSource.find(c => c.name === name), solicitante: frappe.session.user }));
    // Lógica para criar uma nova solicitação com os itens selecionados

    // Exemplo de chamada de API para criar uma nova solicitação
    await frappe.call({
      method: 'nxlite.nx_estoque.page.kanban_de_compras.controller.criar_solicitacao',
      args: {
        itens: { itens: selectedItemsArray }
      },
      callback: (response) => {
        if (response.message) {
          this.showCustomModal('Sucesso', 'Solicitação criada com sucesso!');
          window.selectedItems.clear();
        } else {
          this.showCustomModal('Erro', 'Erro ao criar solicitação.');
        }
      }
    });
  }

  showCustomModal(title, message) {
    const modal = document.createElement('div');
    modal.classList.add('custom-modal');

    const modalContent = document.createElement('div');
    modalContent.classList.add('custom-modal-content');

    const modalHeader = document.createElement('div');
    modalHeader.classList.add('custom-modal-header');
    modalHeader.innerText = title;

    const modalBody = document.createElement('div');
    modalBody.classList.add('custom-modal-body');
    modalBody.innerText = message;

    const modalFooter = document.createElement('div');
    modalFooter.classList.add('custom-modal-footer');

    const closeButton = document.createElement('button');
    closeButton.innerText = 'Fechar';
    closeButton.onclick = () => {
      document.body.removeChild(modal);
    };

    modalFooter.appendChild(closeButton);
    modalContent.appendChild(modalHeader);
    modalContent.appendChild(modalBody);
    modalContent.appendChild(modalFooter);
    modal.appendChild(modalContent);
    document.body.appendChild(modal);
  }
}

// function sortByDate(data, dateField) {
//   return data.sort((a, b) => new Date(a[dateField]) - new Date(b[dateField]));
// }
const real_time = () => {
  frappe.realtime.on("dashboard", async (data) => {
    if (Array.isArray(data)) {
      //ADD OR UPDATE THIS ARRAY
      const bellowEstoqueMin = data.filter(c => c.estoque_min > c.saldo_disponivel_comprar).map(c => c.name)
      // REMOVE ALL ELEMENTS OF THIS ARRAY
      const aboveOrEqualEstoqueMin = data.filter(c => c.estoque_min <= c.saldo_disponivel_comprar).map(c => c.name)
      //BULK DELETE
      kanbanObj.dataSource = kanbanObj.dataSource.filter(c => !aboveOrEqualEstoqueMin.includes(c.name))
      //BULK UPDATE
      kanbanObj.dataSource = kanbanObj.dataSource.map(c => bellowEstoqueMin.includes(c.name) ? data.find(d => d.name == c.name) : c)
      //BULK CREATE
      const allNames = kanbanObj.dataSource.map(c => c.name)
      const toPushIt = bellowEstoqueMin.filter(name => !allNames.includes(name)).map(name => data.find(d => d.name == name))
      kanbanObj.dataSource = kanbanObj.dataSource.concat(toPushIt)
      return
    }
    switch (data.doctype) {
      case 'Cotacao':
      case 'Solicitacao':
      case 'Ordem de Compra':
      case 'Envio para industrializacao':
        if (data.status == 'Aberto') {
          if (kanbanObj.dataSource.some(c => c.name == data.name)) {
            //UPDATE
            kanbanObj.dataSource = kanbanObj.dataSource.map(c => c.name == data.name ? data : c)
            return
          }
          //CREATE
          const newDataSource = kanbanObj.dataSource.concat([data])
          kanbanObj.dataSource = newDataSource
          return
        }
        //DELETE
        kanbanObj.dataSource = kanbanObj.dataSource.filter(c => c.name != data.name)
        return
    }
  });
};

// Função para formatar a data no formato DD-MM-YYYY
function formatarData(data) {
  if (!data) return "N/A";
  const date = new Date(data);
  if (isNaN(date)) return "N/A";
  const dia = String(date.getUTCDate()).padStart(2, "0");
  const mes = String(date.getUTCMonth() + 1).padStart(2, "0");
  const ano = date.getUTCFullYear();
  return `${dia}/${mes}/${ano}`;
};

function dialogOpen(args) {
  args.cancel = false;
}

// Função para abrir o modal
window.openModal = function (Name, doctype) {
  const dataName = kanbanObj.dataSource.find(item => item.name === Name);
  if (!dataName) return;
  if ($('table#datatable').length > 0) {
    $('table#datatable').remove();
  }

  let d = new frappe.ui.Dialog({
    title: `Itens - ${dataName.name}`,
    fields: [
      {
        fieldtype: 'HTML',
        fieldname: 'items',
        label: `Itens`,
        cannot_add_rows: true,
        in_place_edit: false,
        cannot_del_rows: false,
        options: `<table style="min-width: 100%; padding: 0; margin: 0;" id="datatable"></table>`
      }
    ],
    size: 'extra-large'
  });

  d.show();
  setTimeout(async () => {
    let columns = [];

    if (doctype === 'Ordem de Compra') {
      columns = [
        {
          name: 'Código',
          id: 'codigo_item',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Descrição do Item',
          id: 'descricao_item',
          editable: false,
          width: '25%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Quantidade',
          id: 'quantidade',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Unidade de Compra',
          id: 'unidade_de_compra',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Valor Unitário',
          id: 'valor_unitario',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Valor Total',
          id: 'valor_total',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        }
      ];
    } else if (doctype === 'Cotacao') {
      columns = [
        {
          name: 'Código',
          id: 'codigo_item',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Descrição do Item',
          id: 'descricao_item',
          editable: false,
          width: '25%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Quantidade',
          id: 'quantidade',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Unidade de Compra',
          id: 'unidade_de_compra',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        }
      ];
    } else if (doctype === 'Envio para industrializacao') {
      columns = [
        {
          name: 'Descrição da subcontratação',
          id: 'item_desc',
          editable: false,
          width: '20%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'OP',
          id: 'ordem_de_producao',
          editable: false,
          width: '10%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Quantidade e Unidade',
          id: 'quantidade_unidade',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Valor Unitário',
          id: 'valor_unitario_previsto',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Valor Total',
          id: 'valor_total_previsto',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        },
        {
          name: 'Quantidade Recebida',
          id: 'quantidade_recebida',
          editable: false,
          width: '15%',
          resizable: false,
          sortable: false,
          focusable: false,
          dropdown: false,
        }
      ];
    }

    dataName.itens.sort((a, b) => a.codigo_item - b.codigo_item);
    let datatable = new DataTable("#datatable", {
      serialNoColumn: false,
      columns: columns,
      data: dataName.itens.map(item => ({
        codigo_item: item.codigo_item || 'N/A',
        descricao_item: item.descricao_item || 'N/A',
        quantidade: item.quantidade || 'N/A',
        unidade_de_compra: item.unidade_de_compra || 'N/A',
        valor_unitario: 'R$ ' + item.valor_unitario || 'N/A',
        valor_total: 'R$ ' + item.valor_total || 'N/A',
        item_desc: item.item_desc || 'N/A',
        ordem_de_producao: item.ordem_de_producao || '-',
        quantidade_unidade: `${item.quantidade} ${item.unidade_de_estoque}` || 'N/A',
        valor_unitario_previsto: 'R$ ' + item.valor_unitario_previsto || 'N/A',
        valor_total_previsto: 'R$ ' + item.valor_total_previsto || 'N/A',
        quantidade_recebida: item.quantidade_recebida || 0
      })),
      layout: 'ratio',
    });
  }, 200);
};


function getExpirationColor(expirationDate) {
  const currentDate = new Date();
  const expDate = new Date(expirationDate);
  const diffTime = expDate - currentDate;
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

  if (diffDays > 5) {
    return " #d9ffbb"; // Verde
  } else if (diffDays >= 0 && diffDays <= 5) {
    return "#fffea6"; // Amarelo
  } else {
    return "#ffbebe"; // Vermelho
  }
}


function setTheme(selectedTheme) {
  document.getElementById(selectedTheme).disabled = false;
  const otherTheme = selectedTheme === "material3" ? "material3-dark" : "material3";
  document.getElementById(otherTheme).disabled = true;
}

function handleThemeChange(event) {
  const selectedTheme = event.target.value;
  setTheme(selectedTheme);
}

function onDarkMode(e) {
  const selectedTheme = e.checked ? "material3-dark" : "material3";
  setTheme(selectedTheme);
}

//Adicione um seletor de tema no HTML
document.addEventListener('DOMContentLoaded', function () {
  setTheme('material3');
});


// Função para aplicar o tema
function applyTheme() {
  if (document.documentElement.getAttribute('data-theme') === 'dark') {
    setTheme('material3-dark');
  } else {
    setTheme('material3');
  }
}

//Observador de mutações para detectar mudanças no tema
const observer = new MutationObserver((mutations) => {
  mutations.forEach((mutation) => {
    if (mutation.attributeName === 'data-theme-mode' || mutation.attributeName === 'data-theme') {
      applyTheme();
    }
  });
});

// const observer = new MutationObserver((mutations) => {
//   mutations.forEach((mutation) => {
//     if (mutation.attributeName === 'data-theme-mode' || mutation.attributeName === 'data-theme') {
//       if (document.documentElement.getAttribute('data-theme') === 'dark') {
//         setTheme('material3-dark');
//       }
//       else {
//         setTheme('material3');
//       }
//     }
//   });
// });

//Configuração do observador de mutações
observer.observe(document.documentElement, {
  attributes: true,
  attributeFilter: ['data-theme-mode', 'data-theme']
});

window.handleCheckboxChange = function (checkbox, name) {
  if (!window.selectedItems) {
    window.selectedItems = new Set();
  }

  if (checkbox.checked) {
    window.selectedItems.add(name);
  } else {
    window.selectedItems.delete(name);
  }

};

export default SyncFusionKanban;

// Adicionar estilos para o modal customizado
const style = document.createElement('style');
style.innerHTML = `
  .custom-modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
  .custom-modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
  }
  .custom-modal-header {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }
  .custom-modal-body {
    font-size: 1rem;
    margin-bottom: 20px;
  }
  .custom-modal-footer {
    text-align: right;
  }
  .custom-modal-footer button {
    padding: 10px 20px;
    font-size: 1rem;
    border: none;
    border-radius: 4px;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
  }
  .custom-modal-footer button:hover {
    background-color: #0056b3;
  }
`;
document.head.appendChild(style);